* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Barlow', sans-serif;
    background-color: white;
    color: #000000;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

}

.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    margin-top: 0;
}

.container {
    max-width: 2560px;
    margin: 0 auto;
}

main {
    flex: 1;
}

@media screen and (max-width: 720px) {
    #rcc-confirm-button {
        flex: unset !important;
        width: 100% !important;
    }
}


