
.footer {
    background-color: #fff;
    color: #333;
    padding: 48px 80px;
    border-top: 1px solid #e5e5e5;
}

.footer-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer-logo img {
    height: 50px;
}

.footer-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;
}

.footer-links {
    flex: 1;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
    align-items: center;
}

.footer-links ul li a {
    text-decoration: none;
    color: #000000;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24.8px;
    letter-spacing: -0.01em;
    text-align: center;
    text-transform: uppercase;
}

.footer-social {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.footer-social a {
    margin-left: 15px;
}

.footer-social img {
    width: 25px;
    height: 25px;
}

.footer-bottom {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.footer-bottom p {
    text-decoration: none;
    color: #666;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 21.7px;
    letter-spacing: -0.01em;
}

.footer-legal-links {
    display: flex;
    gap: 20px;
}

.footer-legal-links a {
    text-decoration: none;
    color: #666;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 21.7px;
    letter-spacing: -0.01em;

}

.footer-legal-links a:hover {
    text-decoration: underline;
}

/* Mobile styles */
@media (max-width: 768px) {
    .footer {
        padding: 80px 24px;
    }

    .footer-top {
        justify-content: flex-start;
        text-align: center;
    }

    .footer-middle {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        padding: 64px 0;
        gap: 32px;
    }

    .footer-social a {
        margin-left: 0;
        margin-right: 12px;
    }

    .footer-links ul {
        flex-direction: row;
        gap: 10px;
    }

    .footer-social {
        justify-content: center;
        margin-top: 20px;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        gap: 16px;
    }

    .footer-legal-links {
        flex-direction: column;
        gap: 16px;
    }
}
