.privacy-container {
    padding: 20px;
    max-width: 1024px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.privacy-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.privacy-subheading {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-container h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.privacy-paragraph {
    margin-bottom: 10px;
}

.privacy-list {
    padding-left: 20px;
    margin-bottom: 10px;
}
