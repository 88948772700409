.hero {
    position: relative;
    background-image: url('https://cdn.shopify.com/s/files/1/0597/7853/1477/files/Image_d207d04b-163c-4bb7-8d99-f176b05131e9.png?v=1728044512');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2;
    padding: 20px;
    max-width: 893px;
    width: 100%;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.3s;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero h1 {
    font-size: 96px;
    font-weight: bold;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-style: italic;
}

.hero p {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.8px;
    letter-spacing: -0.01em;
    text-align: center;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 64px;
    }

    .hero p {
        font-size: 16px;
    }
}
