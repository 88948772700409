header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: #fff;
    position: relative;
    z-index: 10;
}

.desktop-nav ul li a,
.mobile-menu li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    position: relative;
    pointer-events: auto;
    cursor: pointer;
}

.desktop-nav ul li a:hover,
.mobile-menu li a:hover {
    color: #007bff;
    cursor: pointer;
}

.logo {
    max-width: 150px;
    flex-shrink: 0;
}

.logo img {
    width: 100%;
    height: auto;
}

.desktop-nav {
    display: flex;
}

.desktop-nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

.desktop-nav ul li a {
    text-decoration: none;
    color: #333;
    position: relative;
    pointer-events: auto;
    cursor: pointer;
    z-index: 5;
    font-family: 'Barlow',sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.07em;
    text-transform: uppercase;

}

.desktop-nav ul li a.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #007bff;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
}

.close-bar {
    position: relative;
}

.close-bar-1 {
    transform: rotate(45deg);
}

.close-bar-2 {
    transform: rotate(-45deg);
}

/* Mobile Menu Overlay */


.mobile-menu-overlay.open {
    right: 0;
}

.mobile-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeIn 0.3s forwards;
    pointer-events: auto;
}

.mobile-menu li {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    animation-delay: 0.1s;
}

.mobile-menu li:nth-child(1) {
    animation-delay: 0.2s;
}

.mobile-menu li:nth-child(2) {
    animation-delay: 0.4s;
}

.mobile-menu li a {
    font-size: 24px;
    color: #333;
    text-decoration: none;
}

.mobile-menu li a:hover {
    color: #007bff;
    cursor: pointer;
}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    pointer-events: auto;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Close Icon Styling */
.close-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    cursor: pointer !important;
    z-index: 20;
    margin-left: auto;
}

/* Additional changes */
.mobile-menu-overlay {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 80%;
    background-color: #fff;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: right 0.3s ease;
    padding-right: 16px;
    padding-top: 24px;
}


.close-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
}

.close-bar-1 {
    top: 3px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .desktop-nav {
        display: none;
    }

    .hamburger .close-bar {
        margin: unset;
    }

    .hamburger .close-bar-1 {
        top: 3px;
    }
}

@media (min-width: 769px) {
    .desktop-nav {
        display: flex;
    }

    .mobile-menu-overlay {
        display: none;
    }

    .close-icon {
        display: none;
    }
}
